import { SwipeableDrawer } from "@/components/SwipeableDrawer";
import { Close } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { StyledDrawer, StyledLogoContainer } from "./style";

/**
 * A container component that renders a permanent sidebar for desktop viewports
 *  and a swipeable sidebar for mobile viewports.
 * @param children - The content to be rendered inside the sidebar.
 * @param mobileSidebarOpen - A boolean indicating whether the mobile sidebar is open or closed.
 * @param toggleDrawer - A function to toggle the mobile sidebar open or closed.
 */
const DynamicSidebarContainer = ({
  children,
  mobileSidebarOpen,
  toggleDrawer,
}: {
  children: ReactNode;
  mobileSidebarOpen: boolean;
  toggleDrawer: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`,
  );

  if (!isMobile) {
    return (
      <StyledDrawer variant="permanent" open={true}>
        {children}
      </StyledDrawer>
    );
  }

  return (
    <SwipeableDrawer open={mobileSidebarOpen} toggleDrawer={toggleDrawer}>
      <StyledLogoContainer>
        <div>
          <Typography variant="h4">Mox</Typography>
          <Typography variant="h4">Monolith</Typography>
        </div>
        <IconButton onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </StyledLogoContainer>
      <Divider variant="middle" sx={{ margin: "16px 20px" }} />
      {children}
    </SwipeableDrawer>
  );
};

export default DynamicSidebarContainer;
