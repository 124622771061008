import { ReactNode, createContext, memo, useContext, useState } from "react";

export const ColorModeContext = createContext<{
  toggleColorMode: () => void;
  activeMode: "light" | "dark";
} | null>(null);

export const ColorModeContextProvider = memo(function ColorModeContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useState<"light" | "dark">("dark");

  const toggleColorMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  return (
    <ColorModeContext.Provider value={{ activeMode: mode, toggleColorMode }}>
      {children}
    </ColorModeContext.Provider>
  );
});

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (context === null) {
    throw new Error("Use colorMode must be used within the colorModeProvider");
  }
  return context;
};
