import { css } from "@emotion/react";
import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LogoContainer = styled("div")(
  ({ theme }) => css`
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    :hover h4 {
      color: ${theme.palette.accents.main};
    }

    ${theme.breakpoints.down("sm")} {
      display: flex;
      align-items: center;
      padding: 0;
    }
  `,
);

export const StyledHeader = styled(AppBar)(
  ({ theme }) => css`
    && {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      height: ${`${theme.customVariables.headerHeight}px`};
      background-color: ${theme.palette.surfaces.main};
      border-bottom: 2px solid ${theme.palette.borders.main};
      z-index: 1000;

      color: ${theme.palette.mode === "light"
        ? theme.palette.accents.text
        : theme.palette.accents.text};

      ${theme.breakpoints.down("sm")} {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        justify-content: center;
        position: static;
        max-width: 100vw;
      }
    }
  `,
);

