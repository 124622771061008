import { Button, CircularProgress, MenuItem } from "@mui/material";

import { submitReport } from "@/api";
import {
  CustomizedSelectForFormik as CustomSelect,
  TextField,
} from "@/common/MuiTextField";
import { reasonList } from "@/common/constants";
import { SupportRequestType } from "@/common/types";
import { Field, Form, Formik } from "formik";
import { useCallback } from "react";
import { object, string } from "yup";

interface Values {
  name: string;
  email: string;
  reason: SupportRequestType;
  message: "";
}

const formSchema = object<Values>({
  name: string().required("Please enter your name"),
  email: string().optional().email(),
  reason: string(),
  message: string().optional().max(2000, "Exceeded 2000 character limit"),
});

export const SupportForm = ({ reasonValue, onSuccess, onError }) => {
  const MenuItems = useCallback(() => {
    return reasonList.map((item, index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });
  }, [reasonList]);

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        reason: reasonValue ?? "",
        message: "",
      }}
      validationSchema={formSchema}
      onSubmit={async (values) => {
        try {
          const formattedData = {
            name: values.name,
            email: values.email,
            message: `${values.reason} - ${values.message}`,
          };

          const response = await submitReport(formattedData);

          if (response.ok) {
            onSuccess();
          }
        } catch (error) {
          onError();
        }
      }}
    >
      {({ submitForm, isSubmitting, errors, touched }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "28px 22px",
            gap: "18px",
          }}
        >
          <Field
            labelText="Reason for contacting support"
            name="reason"
            component={CustomSelect}
          >
            {MenuItems()}
          </Field>
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Name"
            placeholder="John Doe"
            error={touched.name && errors.name}
            helperText={touched.name && errors.name}
            fullWidth
          />
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            fullWidth
          />
          <Field
            component={TextField}
            type="message"
            label="Message"
            name="message"
            error={touched.message && errors.message}
            helperText={touched.message && errors.message}
            rows={5}
            multiline
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={submitForm}
            fullWidth
            sx={{
              minHeight: "48px",
            }}
          >
            {isSubmitting ? (
              <CircularProgress color="text" size={25} />
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SupportForm;
