import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  InputLabel,
  TextField as MuiTextField,
  Select,
  useTheme,
} from "@mui/material";
import type { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import type { FieldProps } from "formik";
import { getIn } from "formik";
import { forwardRef } from "react";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    disabled: disabled,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField({ ...props }: TextFieldProps, ref) {
    // @ts-ignore
    return (
      <MuiTextField inputRef={ref} {...(fieldToTextField(props) as any)} />
    );
  },
);

TextField.displayName = "FormikMaterialUITextField";

export const CustomizedSelectForFormik = ({
  style,
  className,
  children,
  form,
  field,
  labelText,
  ...props
}) => {
  const theme = useTheme();
  const { name, value, label } = field;
  const { setFieldValue } = form;

  return (
    <FormControl>
      <InputLabel
        sx={{
          paddingX: 0.5,
          borderRadius: "30px",
          background: theme.palette.surfaces.medium,
        }}
      >
        {labelText}
      </InputLabel>
      <Select
        style={style}
        name={name}
        value={value}
        label={label}
        fullWidth
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};


export function CheckboxField({ style, className, ...props }: CheckboxProps) {
    const innerProps = fieldToTextField(props as any);

    return (
        <Box
            style={style}
            className={className}
            display="flex"
            alignItems="center"
            gap="0.25em"
        >
            <Checkbox
                {...(innerProps as any)}
                {...(innerProps.value === "true" ||
                innerProps.value === true ||
                props.checked
                    ? { checked: true }
                    : { checked: false })}
                style={{ padding: 0 }}
            />
        </Box>
    );
}
