/* eslint-disable @typescript-eslint/no-empty-interface */
import { PaletteMode, createTheme } from "@mui/material";

// `White` = "#f9faf4",
// `Blue` = "#0e68ab",
// `Black` = "#150b00",
// `Red` = "#d3202a",
// `Green` = "#00733e",
// `Multicolored` = "#F3DB6F",
// `Colorless` = "#c6c5c5"

declare module "@mui/material/styles" {
  interface CustomTheme {
    boxShadows: {
      main: string;
    };
    headerHeight: number;
    customVariables: { drawerWidth: number; headerHeight: number };
  }

  interface CustomTypographyVariants {
    captionBold: React.CSSProperties;
    captionSmall: React.CSSProperties;
    bodyBold: React.CSSProperties;
  }

  interface CustomPalette {
    surfaces: {
      main: string;
      light: string;
      medium: string;
      mediumLight: string;
      dark: string;
    };
    accents: {
      muted: string;
      disabled: string;
      main: string;
      text: string;
      textDark: string;
    };
    borders: {
      main: string;
    };
    slateGray:{
      main: string;
      contrastText: string;
    }
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface TypographyVariants extends CustomTypographyVariants {}
  interface TypographyVariantsOptions extends CustomTypographyVariants {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
    primaryDark: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    slateGray: true;
}}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    captionBold: true;
    captionSmall: true;
    bodyBold: true;
  }
}

/** https://colorffy.com/dark-theme-generator
 * --color-primary-100: #5b78fd;
--color-primary-200: #7486fe;
--color-primary-300: #8994ff;
--color-primary-400: #9ca3ff;
--color-primary-500: #aeb1ff;
--color-primary-600: #bfc0ff;

--color-surface-100: #0a0a0a;
--color-surface-200: #232323;
--color-surface-300: #3a3a3a;
--color-surface-400: #535353;
--color-surface-500: #6d6d6d;
--color-surface-600: #888888;

--color-surface-mixed-100: #161721;
--color-surface-mixed-200: #2b2c36;
--color-surface-mixed-300: #42434c;
--color-surface-mixed-400: #5a5b63;
--color-surface-mixed-500: #73747b;
--color-surface-mixed-600: #8e8e94;

 */

const lightModePalette = {
  // ===== LIGHT MODE =====
  mode: "light",
  primary: {
    main: "#5b78fd",
    contrastText: "#000000",
  },
  accents: {
    muted: "#8e8e94",
    main: "#7486fe",
    disabled: "#bbbbbb",
    text: "#000000",
  },
  surfaces: {
    main: "#f9f9ff",
    light: "#f9f9ff",
    medium: "#eaeafb",
    mediumLight: "#f0f0f6",
  },
  borders: {
    main: "#8e8e94",
  },
  slateGray:{
    main: "#5C5F70",
    contrastText: "#FFFFFF",
  }
};

const darkModePalette = {
  // ===== DARK MODE =====
  mode: "dark",
  primary: {
    // 100: #5b78fd;
    // 200: #7486fe;
    // 300: #8994ff;
    // 400: #9ca3ff;
    // 500: #aeb1ff;
    // 600: #bfc0ff;
    main: "#5b78fd",
  },
  accents: {
    muted: "#8e8e94",
    main: "#bfc0ff",
    disabled: "#bbbbbb",
    text: "#FFFFFF",
    textDark: "#b3b3b3",
  },
  surfaces: {
    // mixed-100: #161721;  body/main
    // mixed-200: #2b2c36;  cards / medium
    // mixed-300: #42434c;  chips/ buttons  /light
    // mixed-400: #5a5b63;  nav/sidebars /mediumlight
    // mixed-500: #73747b;  modals/dialogs
    // mixed-600: #8e8e94;  muted text
    // https://coolors.co/161721-212231-2b2c36-42434c-5a5b63
    main: "#161721",
    dark: "#363950",
    light: "#42434c",
    medium: "#2b2c36",
    mediumLight: "#5a5b63",
  },
  borders: {
    main: "#42434c",
  },
  slateGray:{
    main: "#5C5F70",
    contrastText: "#FFFFFF",
  }
};

const typographyVariants = {
  h3: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "110%",
  },
  h4: {
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "110%",
  },
  h5: {
    fontWeight: 800,
    fontSize: "12px",
    lineHeight: "150%",
  },
  captionBold: {
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "140%",
  },
  captionSmall: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "140%",
  },
  body1: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "150%",
  },
  bodyBold: {
    fontWeight: 800,
    fontSize: "16px",
    lineHeight: "150%",
  },
};

const customVariables = {
  drawerWidth: 280,
  headerHeight: 80,
};

export const colorModeTheme = (mode: PaletteMode) => {
  const theme = createTheme({
    //@ts-ignore
    palette: mode === "light" ? lightModePalette : darkModePalette,
    customVariables: customVariables,
    typography: {
      fontFamily: "Inter, sans-serif",
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      ...typographyVariants,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768, // table
        md: 1024, // desktop
        lg: 1360,
        xl: 1536,
      },
    },
    boxShadows: {
      main: "0px 1.13px 3.38px rgba(0, 0, 0, 0.11)",
    },
    headerHeight: 80,
    components: {
      MuiCssBaseline: {
        // These are effectively global styles applied to the <body> element.
        styleOverrides: `
         html {
          overflow-x: hidden;
        }

        #app {
          flex-grow:1;
          display:flex;
          min-height:100vh;
          min-width:100vw;
          box-sizing:border-box;
          background-color: ${
            mode === "light"
              ? lightModePalette.surfaces.main
              : darkModePalette.surfaces.main
          };
        }

        body {
          font-family: 'Inter', sans-serif; 
          color:
              ${
                mode === "light"
                  ? lightModePalette.accents.text
                  : darkModePalette.accents.text
              };

          ::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: #5a5b63; 
          }

          ::-webkit-scrollbar-thumb {
            background: #2b2c36;
          }
        }

          

        a {
          text-decoration: none;
          &:focus, &:hover, &:visited, &:link, &:active {
              text-decoration: none;
              color: initial;
            }
          }
      `,
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,

            textTransform: "none",
            boxShadow: "none",
            "&:hover": { boxShadow: "none" },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          // disableRipple: true,
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "accents.text",
          fontFamily: "Inter, sans-serif",
          variantMapping: {
            caption: "p",
            captionBold: "p",
            captionSmall: "p",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              backgroundColor: "#0C0C0C",
              color: "#FFFFFF",
              flexGrow: 1,
            },
            "& .MuiAlert-message": {
              fontSize: "13px",
              fontWeight: "800",
              lineHeight: "140%",
            },
          },
        },
      },
    },
  });

  return theme;
};

