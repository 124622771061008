import { SwipeableDrawer as MUIDrawer } from "@mui/material";
import { ReactNode } from "react";

export const SwipeableDrawer = ({
  open,
  toggleDrawer,
  children,
  anchor = "left",
  width = "85vw",
  ...rest
}: {
  open: boolean;
  toggleDrawer: (arg: boolean) => void;
  children: ReactNode;
  anchor?: "left" | "bottom" | "right" | "top";
  width?: string;
  [other: string]: any;
}) => {
  // The following code is from https://mui.com/components/drawers/#swipeable-drawer
  // iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <MUIDrawer
      {...rest}
      sx={{
        "& > .MuiPaper-root": {
          width: width,
          boxSizing: "border-box",
        },
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor={anchor}
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      {children}
    </MUIDrawer>
  );
};
