import { colorModeTheme } from "@/common/theme";
import { SupportContextProvider } from "@/contexts/contact-support-context";
import { useColorMode } from "@/contexts/palette-context";
import { SearchHistoryContextProvider } from "@/contexts/search-history-context";
import { SnackbarContextProvider } from "@/contexts/snackbar-context";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useMemo } from "react";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "use-query-params/adapters/window";

const queryClient = new QueryClient();

const Providers = ({ children }: { children: ReactNode }) => {
  const { activeMode } = useColorMode();

  const theme = useMemo(() => {
    return colorModeTheme(activeMode);
  }, [activeMode]);

  return (
    <ThemeProvider theme={theme}>
      <QueryParamProvider adapter={WindowHistoryAdapter}>
        <QueryClientProvider client={queryClient}>
          <SnackbarContextProvider>
            <SupportContextProvider>
              <SearchHistoryContextProvider>
                <CssBaseline />
                {children}
              </SearchHistoryContextProvider>
            </SupportContextProvider>
          </SnackbarContextProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </ThemeProvider>
  );
};

export default Providers;
