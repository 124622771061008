// export async function makeAuthedRequest(url, method, data = {}) {
//     let token = localStorage.getItem("token");

import Cookies from "js-cookie";

//     if (token) {
//       token = JSON.parse(localStorage.getItem("token")).value;
//     }

//     return await axiosInstance({
//       method,
//       url,
//       data,
//       // headers: { Authorization: "Bearer " + token },
//     })
//       .then(function (response) {
//         return response;
//       })
//       .catch(function (error) {
//         if (error.response) {
//           console.log(">>>", error.response.status);
//           // Unauthenticated error
//           if (error.response.status === 401) {
//             logout();
//             window.location.reload();
//           }
//         }
//         return error;
//       });
//   }

export async function makeRequest<TResponse>({
  url,
  method = "POST",
  body,
  headers = {},
}: {
  url: string;
  method?: string;
  body?: RequestInit["body"];
  headers?: RequestInit["headers"];
  }): Promise<TResponse> {
  
  const defaultHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN") ?? "",
    }
  
  const mergedHeaders = {
    ...defaultHeaders,
    ...headers,
  };

  const response = await fetch(url, {
    body: body,
    method: method,
    headers: mergedHeaders,
  });

  if (!response.ok) {
    return await response.json();
  }

  return await response.json() as TResponse;
}
