import { useColorMode } from "@/contexts/palette-context";
import { Link, usePage } from "@inertiajs/react";
import { Menu, WhatsApp } from "@mui/icons-material";

import { Box, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../Logo";
import UserMenu from "./UserMenu";
import { LogoContainer, StyledHeader } from "./style";

const PAGE_NAME_MAP = {
  "/": "Card Search",
  "/roadmap": "Roadmap",
  "/faq": "FAQ Page",
  "/changelog": "Changelogs",
};

const Header = ({ toggleMenu }: { toggleMenu: () => void }) => {
  const { toggleColorMode, activeMode } = useColorMode();
  const { url, props } = usePage<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`,
  );

  const user = props.user?.data;

  const DynamicHeaderContent = () => {
    if (isMobile) {
      return (
        <>
          <IconButton onClick={toggleMenu}>
            <Menu />
          </IconButton>
          <Typography
            variant="h4"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {PAGE_NAME_MAP[url.split("?")[0]]}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              a: {
                color: "#FFF",
                textDecoration: "none",
                padding: "8px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: theme.palette.surfaces.medium,
                },
              },
            }}
          >
            {!user ? (
              <>
                <Link href={"/login"}>Login</Link>
              </>
            ) : (
              <UserMenu user={user} />
            )}
          </Box>
        </>
      );
    }

    return (
      <>
        <Link href={"/"}>
          <LogoContainer>
            <Logo />
            <Box>
              <Typography variant="h4">Mox</Typography>
              <Typography variant="h4">Monolith</Typography>
            </Box>
          </LogoContainer>
        </Link>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            a: {
              color: "#FFF",
              textDecoration: "none",
              padding: "8px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: theme.palette.surfaces.medium,
              },
            },
          }}
        >
          {!user ? (
            <>
              <Link href={"/login"}>Login</Link>
            </>
          ) : (
            <UserMenu user={user} />
          )}
          <a
            href={"https://chat.whatsapp.com/ILIVNsecjJWC5pXd2bHVlB"}
            target="_blank"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WhatsApp
                sx={{ color: activeMode === "light" ? "#58e905" : "#FFF" }}
              />
            </Box>
          </a>
        </Box>
      </>
    );
  };

  return (
    <StyledHeader position="fixed">
      <Toolbar
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <DynamicHeaderContent />
      </Toolbar>
    </StyledHeader>
  );
};

export default Header;
