import { ActiveCardType } from "@/common/types";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useCookie } from "react-use";

const MAX_SEARCH_HISTORY = 12;

/**
 * Returns a new search history array with the given item added to the front and duplicates removed.
 * If the new history length exceeds the max length, the oldest item is removed from the end.
 * @param newItem - The new item to add to the search history.
 * @param history - The current search history array.
 * @param max - The maximum length of the search history array.
 * @returns A new search history array with the given item added to the front and duplicates removed.
 */
const getNewSearchHistory = (
  newItem: ActiveCardType,
  history: ActiveCardType[],
  max: number = MAX_SEARCH_HISTORY,
) => {
  const newHistory = history.filter((item) => item.id !== newItem.id);

  newHistory.unshift(newItem);

  if (newHistory.length > max) {
    newHistory.pop();
  }

  return newHistory;
};

/**
 * Removes an item from the search history array.
 * @param itemToDelete - The item to be removed from the current search history.
 * @param history - The current search history array.
 * @returns A new search history array with the given item removed.
 */
const deleteSearchHistoryItem = (
  itemToDelete: ActiveCardType,
  history: ActiveCardType[],
) => {
  const newHistory = history.filter((item) => item.id !== itemToDelete.id);

  return newHistory;
};

export type SearchHistoryContextState = {
  searchHistory: ActiveCardType[] | [];
  updateSearchHistory: (arg: ActiveCardType) => void;
  showHistory: boolean;
  toggleSearchHistoryEnabled: () => void;
  clearSearchHistory: () => void;
  deleteSearchItem: (arg: ActiveCardType) => void;
};

export const SearchHistoryContext =
  createContext<SearchHistoryContextState | null>(null);

export const SearchHistoryContextProvider = memo(
  function SearchHistoryContextProvider({ children }: { children: ReactNode }) {
    const [historyCookie, updateHistoryCookie] = useCookie("search-history");

    const parsedHistoryCookie = useMemo((): {
      items: ActiveCardType[] | null;
      enabled: boolean;
    } => {
      if (!historyCookie) {
        return { items: null, enabled: false };
      }
      return JSON.parse(historyCookie);
    }, [historyCookie]);

    const [showHistory, setShowHistory] = useState(
      parsedHistoryCookie?.enabled,
    );

    const [searchHistory, setSearchHistory] = useState<ActiveCardType[] | []>(
      parsedHistoryCookie?.items ?? [],
    );

    const updateSearchHistory = useCallback(
      (newItem: ActiveCardType) => {
        const newHistory = getNewSearchHistory(newItem, searchHistory);

        updateHistoryCookie(
          JSON.stringify({
            items: newHistory,
            enabled: parsedHistoryCookie?.enabled,
          }),
        );
        setSearchHistory(newHistory);
      },
      [updateHistoryCookie, parsedHistoryCookie],
    );

    const toggleSearchHistoryEnabled = useCallback(() => {
      updateHistoryCookie(
        JSON.stringify({
          items: searchHistory,
          enabled: !showHistory,
        }),
      );
      setShowHistory(!showHistory);
    }, [updateHistoryCookie, parsedHistoryCookie]);

    const clearSearchHistory = useCallback(() => {
      updateHistoryCookie(
        JSON.stringify({
          items: [],
          enabled: showHistory,
        }),
      );
      setSearchHistory([]);
    }, [updateHistoryCookie, parsedHistoryCookie]);

    const deleteSearchItem = useCallback(
      (deletedItem: ActiveCardType) => {
        const newHistory = deleteSearchHistoryItem(deletedItem, searchHistory);

        updateHistoryCookie(
          JSON.stringify({
            items: newHistory,
            enabled: parsedHistoryCookie?.enabled,
          }),
        );
        setSearchHistory(newHistory);
      },
      [updateHistoryCookie, parsedHistoryCookie],
    );

    const value = useMemo(() => {
      return {
        searchHistory,
        updateSearchHistory,
        showHistory,
        toggleSearchHistoryEnabled,
        clearSearchHistory,
        deleteSearchItem,
      };
    }, [
      searchHistory,
      updateSearchHistory,
      showHistory,
      toggleSearchHistoryEnabled,
      clearSearchHistory,
      deleteSearchItem,
    ]);

    return (
      <SearchHistoryContext.Provider value={value}>
        {children}
      </SearchHistoryContext.Provider>
    );
  },
);

export const useSearchHistory = () => {
  const context = useContext(SearchHistoryContext);
  if (context === null) {
    throw new Error(
      "Use search history must be used within the SearchHistoryProvider",
    );
  }
  return context;
};
