import { ColorModeContextProvider } from "@/contexts/palette-context";
import Providers from "@/service-components/Providers";
import { Head } from "@inertiajs/react";
import { Box, Typography, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import TagManager from "react-gtm-module";
import Header from "./Header";
import { Sidebar } from "./Sidebar";

const tagManagerArgs = {
  gtmId: "GTM-PFQ6LW2",
};

if (
  import.meta.env.VITE_APP_MODE === "production" ||
  import.meta.env.VITE_APP_MODE === "staging"
) {
  TagManager.initialize(tagManagerArgs);
}

export const Layout = ({ children }: { children: ReactNode }) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const theme = useTheme();

  return (
    <ColorModeContextProvider>
      <Providers>
        <Head title={"Mox Monolith"} />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <>
            <Header
              toggleMenu={() => setMobileSidebarOpen(!mobileSidebarOpen)}
            />
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                [theme.breakpoints.up("sm")]: {
                  marginTop: "80px",
                },
              }}
            >
              <Sidebar
                mobileSidebarOpen={mobileSidebarOpen}
                setMobileSidebarOpen={setMobileSidebarOpen}
              />
              {children}
            </Box>
            <Typography
              sx={{
                position: "absolute",
                bottom: "0",
                right: "20px",
                fontSize: "12px",
              }}
              color="accents.muted"
            >
              © 2020-2024 | RouxtAccess
            </Typography>
          </>
        </Box>
      </Providers>
    </ColorModeContextProvider>
  );
};
