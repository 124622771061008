import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import type { SnackbarOrigin } from "@mui/material/Snackbar";
import Snackbar from "@mui/material/Snackbar";
import {
    ReactNode,
    createContext,
    memo,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
export type Message = {
    message: string;
    origin?: SnackbarOrigin;
    type?: "success" | "error" | "warning" | "info";
};
export type SnackbarContextState = {
    messages: Message[];
    push: (message: Message) => void;
    pop: (message: Message) => void;
};
export const SnackbarContext = createContext<SnackbarContextState | null>(null);

const duration = 5000;

export const SnackbarContextProvider = memo(function SnackbarContextProvider({
    children,
}: {
    children: ReactNode;
}) {
    const [messages, setMessages] = useState<Message[]>([]);
    const push = useCallback(
        (message: Message) => setMessages((messages) => [...messages, message]),
        [setMessages],
    );
    const pop = useCallback(
        (message: Message) => {
            setMessages((messages) => messages.filter((mes) => mes != message));
        },
        [setMessages],
    );
    const value = useMemo(() => {
        return {
            messages,
            push,
            pop,
        };
    }, [messages, push, pop]);
    return (
        <SnackbarContext.Provider value={value}>
            {messages.map((message, i) => {
                if (message.type) {
                    return (
                        <Snackbar
                            key={`${message.message}${i}`}
                            open={true}
                            onClose={() => pop(message)}
                            autoHideDuration={duration}
                            anchorOrigin={
                                message.origin ?? {
                                    vertical: "bottom",
                                    horizontal: "right",
                                }
                            }
                            message={message.message}
                        >
                            <Alert
                                icon={false}
                                key={`${message.message}${i}`}
                                onClose={() => pop(message)}
                                severity={message.type}
                                sx={{ display: "flex", alignItems: "center" }}
                                action={
                                    <IconButton
                                        onClick={() => pop(message)}
                                        size="large"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                            >
                                {message.message}
                            </Alert>
                        </Snackbar>
                    );
                }
                return (
                    <Snackbar
                        key={`${message.message}${i}`}
                        open={true}
                        autoHideDuration={duration}
                        onClose={() => pop(message)}
                        anchorOrigin={
                            message.origin ?? {
                                vertical: "bottom",
                                horizontal: "right",
                            }
                        }
                    >
                        <Alert
                            icon={<CheckCircleIcon />}
                            onClose={() => pop(message)}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            {message.message}
                        </Alert>
                    </Snackbar>
                );
            })}
            {children}
        </SnackbarContext.Provider>
    );
});
const useSnackbarContext = () => {
    const context = useContext(SnackbarContext);
    if (context === null) {
        throw new Error(
            "Use snackbar must be used within the SnackbarProvider",
        );
    }
    return context;
};
export const useSnackbar = () => {
    const context = useSnackbarContext();
    const toast = useCallback(
        (message: Message) => context.push(message),
        [context],
    );
    return toast;
};
