import { Box } from "@mui/material";
import logoImage from "../../assets/monolith.svg";

const Logo = () => {
  return (
    <Box sx={{ position: "relative" }}>
      {/* <Box
        sx={{
          //   clipPath: "polygon(50% 0%, 81% 0, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 19% 0)",
          //   clipPath: "polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)",
          // clipPath: "circle(35% at 49% 43%)",
          // background: "#C2CDFE",
          border: "2px solid #5b78fd",
          width: "70px",
          height: "70px",
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 1,
          // transform: "translate(-50%, -60%)",
        }}
      /> */}
      <img
        src={logoImage}
        style={{
          width: "60px",
          height: "auto",
          zIndex: 2,
          position: "relative",
        }}
      />
    </Box>
  );
};

export default Logo;
