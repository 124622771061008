import { Store } from "@mui/icons-material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { SupportRequestType } from "./types";

export const QUERY_KEY_FETCH_PRODUCTS = "fetch-stale-products";
export const MENU_ITEMS = [
  {
    title: "Card Search",
    icon: <ScreenSearchDesktopIcon />,
    path: "/",
    divider: true,
  },
  {
    title: "Vendors",
    icon: <Store />,
    path: "/vendors",
    isNew: true,
  },
  {
    title: "FAQ",
    icon: <SettingsSuggestIcon />,
    path: "/faq",
  },
  {
    title: "Changelogs",
    icon: <ManageHistoryIcon />,
    path: "/changelog",
  },
  // {
  //   title: "Analytics",
  //   icon: <ManageHistoryIcon />,
  //   path: "/changelog",
  //   divider: true,
  // },
  // {
  //   title: "Wishlists",
  //   icon: <ManageHistoryIcon />,
  //   path: "/changelog",
  // },
  // {
  //   title: "Settings",
  //   icon: <ManageHistoryIcon />,
  //   path: "/changelog",
  // },
];

export const reasonList: { label: string; value: SupportRequestType }[] = [
  { label: "Request a new Vendor", value: "vendor-request" },
  { label: "Request an integration", value: "integration-request" },
  { label: "Request a new deckbox addition", value: "deckbox-request" },
  { label: "Feature request", value: "feature-request" },
  { label: "General feedback", value: "general-feedback" },
  { label: "Report an issue", value: "bug-report" },
];
