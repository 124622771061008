import { SupportRequestType } from "@/common/types";
import SupportForm from "@/components/SupportForm";
import { css } from "@emotion/react";
import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Modal,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "./snackbar-context";

const StyledModalContainer = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    box-shadow: 24px;
    padding: 0;
    background-color: ${theme.palette.surfaces.medium};
    border-radius: 8px;

    ${theme.breakpoints.down("sm")} {
      width: 100%;
      position: static;
      transform: none;
    }
  `,
);

export type SupportContextState = {
  openSupportModal: (reason: SupportRequestType) => void;
  closeSupportModal: () => void;
};

export const SupportContext = createContext<SupportContextState | null>(null);

export const SupportContextProvider = memo(function SupportContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const [contactReason, setContactReason] = useState<SupportRequestType | null>(
    null,
  );

  const openSupportModal = (reason: SupportRequestType) => {
    // maybe gtm tracking
    setContactReason(reason);
  };

  const closeSupportModal = () => {
    setContactReason(null);
  };

  const value = useMemo(() => {
    return {
      openSupportModal,
      closeSupportModal,
    };
  }, [openSupportModal, closeSupportModal]);

  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`,
  );

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SupportContext.Provider value={value}>
      {isMobile ? (
        <>
          <SwipeableDrawer
            sx={{
              "& .MuiDrawer-paper": {
                borderRadius: "8px 8px 0 0",
                background: theme.palette.surfaces.medium,
              },
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            anchor={"bottom"}
            onOpen={() => null}
            open={Boolean(contactReason)}
            onClose={closeSupportModal}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <StyledModalContainer>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "16px",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Contact Us</Typography>
                <IconButton onClick={closeSupportModal}>
                  <Close />
                </IconButton>
              </Box>
              <SupportForm
                reasonValue={contactReason}
                onSuccess={() => {
                  closeSupportModal();
                  snackbar({
                    message: "Form submitted successfully",
                    type: "success",
                  });
                }}
                onError={() => {
                  snackbar({
                    message: "Something went wrong. Please try again.",
                    type: "error",
                  });
                }}
              />
            </StyledModalContainer>
          </SwipeableDrawer>
        </>
      ) : (
        <Modal open={Boolean(contactReason)} onClose={closeSupportModal}>
          <StyledModalContainer>
            <Typography
              variant="h4"
              sx={{
                backgroundColor: theme.palette.primary.main,
                padding: "22px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              Contact Us
            </Typography>
            <SupportForm
              reasonValue={contactReason}
              onSuccess={() => {
                closeSupportModal();
                snackbar({
                  message: "Form submitted successfully",
                  type: "success",
                });
              }}
              onError={() => {
                snackbar({
                  message: "Something went wrong. Please try again.",
                  type: "error",
                });
              }}
            />
          </StyledModalContainer>
        </Modal>
      )}

      {children}
    </SupportContext.Provider>
  );
});

export const useContactSupport = () => {
  const context = useContext(SupportContext);
  if (context === null) {
    throw new Error("Use Support must be used within the SupportProvider");
  }
  return context;
};
