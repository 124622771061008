import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.customVariables.drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: theme.palette.surfaces.medium,
  overflowX: "hidden",
  

  "& .MuiPaper-root": {
    backgroundColor: theme.palette.surfaces.medium,
    top: theme.customVariables.headerHeight,
    position: "fixed",

    [theme.breakpoints.up("sm")]: {
      paddingTop: "20px",
    },
  },

  "& .MuiDrawer-paper": {
    width: theme.customVariables.drawerWidth,
  },
}));

export const StyledNavItem = styled("div")<{ active?: boolean }>(
  ({ theme, active }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px 20px",
    marginLeft: 0,

    color: active ? theme.palette.primary.main : theme.palette.accents.text,

    "& p": {
      fontWeight: 800,
      color: "inherit",
      display: "block",
    },

    "& svg": {
      marginRight: "16px",
    },
    "&:hover": {
      color: active ? theme.palette.primary.main : theme.palette.accents.main,

      "& p": {
        color: "inherit",
      },
    },

    [theme.breakpoints.up("sm")]: {
      padding: "20px",
      marginLeft: "12px",
    },
  }),
);

export const StyledLogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  textTransform: "uppercase",
  padding: "20px 20px 8px 20px",

  "& button svg": {
    color: theme.palette.primary.contrastText,
  },
}));

export const StyledSupportButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignContent: "center",
  flexGrow: 1,
  padding: "20px 20px 36px 20px",

  "& p": {
    fontWeight: 400,
    color: "inherit",
    display: "block",
  },

  "& svg": {
    fontSize: "large",
  },

  [theme.breakpoints.up("sm")]: {
    marginLeft: "12px",
  },
}));