import { createInertiaApp } from "@inertiajs/react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Layout } from "./components/Layout";

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      if (import.meta.env.VITE_APP_MODE !== "production") {
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
        }
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

registerServiceWorker();

createInertiaApp({
  title: (title) => `${title}`,
  resolve: async (name) => {
    // "Laravel does not officially support Typescript" so we have to use any here
    const pages: any = import.meta.glob("./Pages/**/*.tsx");
    const page = (await pages[`./Pages/${name}.tsx`]())?.default;
    // Sets a default layout if none was provided
    if (page && !page.layout) {
      page.layout = (page) => <Layout children={page} />;
    }
    return page;
  },
  /**
   * Sets up the application by creating a root element and rendering the App component.
   * If the application is in production or staging mode, initializes Sentry for error tracking and performance monitoring.
   * If the application is not in production mode, logs the initial page component and its props to the console.
   */
  setup({ el, App, props }) {
    const root = createRoot(el);

    if (
      import.meta.env.VITE_APP_MODE === "production" ||
      import.meta.env.VITE_APP_MODE === "staging"
    ) {
      Sentry.init({
        dsn: "https://ea9646e6b62eb2c580ff078548187975@o1160857.ingest.sentry.io/4506060908199936",
        environment: import.meta.env.VITE_APP_MODE ?? "development",
        integrations: [new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 0.01, // Capture 100% of the transactions
        // Session Replay - heavy on bundle size so keep these disabled for now
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
      });
    }

    if (import.meta.env.VITE_APP_MODE !== "production") {
      const style =
        "background-color: darkblue; color: white; font-style: italic; border: 3px solid rebeccapurple; font-size: 2em;";
      console.log(`%cPage Name: ${props.initialPage.component}`, style);
      console.group("%cPage Props:", style);

      const smallStyle =
        "background-color: darkblue; color: white; font-style: italic; border: 1px solid rebeccapurple; font-size: 1.5em;";
      Object.keys(props.initialPage.props).forEach((prop) => {
        console.group(`%c${prop}`, smallStyle);
        console.log(prop, props.initialPage.props[prop]);
        console.groupEnd();
      });
      console.groupEnd();
    }

    root.render(<App {...props} />);
  },
});
